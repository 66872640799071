<template>
  <div v-if="profile">
    <h2 class="font-bold text-2xl m-0 p-0">
      Jouw gegevens
    </h2>
    <div class="mb-2 mt-1">
      <span class="font-bold">E-mail:</span> {{ profile.email }}
    </div>
    <div v-if="gegevens?.length" class="row">
      <div
        v-for="(el, index) in (gegevens || []).filter(el => !!el)"
        :key="String(el.id || '?') + index + gegevens.length"
        class="w-full lg:w-1/2 inline-block p-2"
        :class="{ 'lg:w-1/2': gegevens.length > 1 }"
      >
        <div class="w-full border-1 border-gray-200 rounded border-l-3 border-l-kevin p-1">
          <table>
            <thead>
              <tr class="text-left">
                <th colspan="2" class="pb-1">
                  Persoon gegevens {{ index + 1 }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-sm">
                <td class="font-bold" style="width: 140px;">
                  Voornaam
                </td>
                <td v-if="!el.edit" :key="el.firstname">
                  {{ el.firstname || '?' }}
                </td>
                <td v-else>
                  <input
                    v-model="form[index].firstname"
                    type="text"
                    class="form-control"
                    placeholder="Voornaam"
                  >
                </td>
              </tr>
              <tr class="text-sm">
                <td class="font-bold" style="width: 140px;">
                  Familienaam
                </td>
                <td v-if="!el.edit" :key="el.lastname">
                  {{ el.lastname || '?' }}
                </td>
                <td v-else>
                  <input
                    v-model="form[index].lastname"
                    type="text"
                    class="form-control"
                    placeholder="Familienaam"
                  >
                </td>
              </tr>
              <tr class="text-sm">
                <td class="font-bold" style="width: 140px;">
                  Telefoon
                </td>
                <td v-if="!el.edit" :key="el.phone">
                  {{ el.phone || '?' }}
                </td>
                <td v-else>
                  <input
                    v-model="form[index].phone"
                    type="text"
                    class="form-control"
                    placeholder="Telefoon"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="block mb-2 mx-auto" style="max-width: 80%;">
    <div lang="nl">
      <span v-show="!showPrivacy" class="block text-kevin text-center cursor-pointer" @click="showPrivacy = true">Bekijk onze privacy maatregelen</span>
      <div v-show="showPrivacy">
        <div class="bg-green-100 border-2 rounded py-2 border-green-300 text-center mb-3">
          Privacy is geen bijzaak voor ons maar een <b>noodzaak</b> naar onze klanten toe!
        </div>
        <ul class="list-square m-0 ml-4">
          <li class="py-1">
            Elke voornaam, familienaam, mailadres, telefoonnummer en adressen worden geëncrypteerd.
            <br>Hierdoor zijn deze onleesbaar voor kwaadwillige partijen.
          </li>
          <li class="py-1">
            Wachtwoorden worden opgeslagen op een manier dat jouw wachtwoord zelf voor ons onbekend blijft (Hashing).
          </li>
          <li class="py-1">
            Je kan op verschillende plaatsen op de website je akkoord geven om nieuwsbrieven te ontvangen, uitschrijven kan steeds via onze nieuwsbrieven zelf.
          </li>
        </ul>
        <span class="block mb-1 mt-3 font-bold text-lg">GDPR Account verwijderen</span>
        <p>
          Wij doen er alles aan om jouw privacy te garanderen!<br>
          Toch heeft elke persoon steeds het "recht op vergeten".<br>
          Je kan dan ook op eender welk moment een aanvraag indienen om jouw account te verwijderen.<br>
          Weet dat het volgende geldt indien je hiermee doorgaat:<br>
        </p>
        <ul class="list-square m-0 ml-4">
          <li class="py-1">
            Indien je geboekt heeft bij ons, houden we de noodzakelijke gegevens voor onze boekhouding bij zolang dit wettelijk verplicht is.
          </li>
          <li class="py-1">
            Je zal binnen de 7 dagen uit al onze nieuwsbrief systemen worden verwijderd.
          </li>
          <li class="py-1">
            Je zal niet meer kunnen inloggen op jouw account.
          </li>
        </ul>
        <p class="pt-4">
          Je kan een mail sturen naar info@luchthavenvervoer-kevin.be om een aanvraag in te dienen voor het verwijderen van je account.
        </p>
      </div>
    </div>
  </div>
  <div>
    {{ store.state.profile }}
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import useGetApi from '@/hooks/useGetApi'

const store = useStore()

const showPrivacy = ref(false)
const profile = computed(() => store.state.profile)
const { data: gegevens /* , loading */ } = useGetApi('KLANT_GEGEVENS')
</script>
